// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/Confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-services-strapi-services-slug-tsx": () => import("./../../../src/pages/services/{StrapiServices.slug}.tsx" /* webpackChunkName: "component---src-pages-services-strapi-services-slug-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/Document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/Project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */)
}

