import React from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'

import theme from './theme'
import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}: WrapPageElementBrowserArgs) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  )
}
