import { Box, withStyles, createStyles, Theme } from '@material-ui/core'

export const containerPaddingX = {
  xs: '1.875rem',
  md: '4.375rem',
  xl: '16.625rem',
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      paddingLeft: containerPaddingX.xs,
      paddingRight: containerPaddingX.xs,
      [theme.breakpoints.up('md')]: {
        paddingLeft: containerPaddingX.md,
        paddingRight: containerPaddingX.md,
        width: '81%',
        boxSizing: 'content-box',
        margin: '0 auto',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '65.375rem',
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: containerPaddingX.xl,
        paddingRight: containerPaddingX.xl,
        maxWidth: '86.75rem',
      },
    },
  })
}

export default withStyles(styles)(Box)
