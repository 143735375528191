import { createTheme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { containerPaddingX } from '../components/PageContainer'

import { getFonts } from '../utils/fonts'
import { fontSizeVariants } from './fontSizeVariants'

const mainColor = '#0F348F'

const theme = createTheme({
  typography: {
    fontFamily: getFonts(),
    button: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: mainColor,
      contrastText: 'white',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: mainColor,
    },
    text: {
      primary: '#100020',
      secondary: '#666565',
    },
    background: {
      default: '#FFF',
    },
    divider: '#C0CCDA',
    error: { main: '#cc0000' },
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 0,
        boxShadow: 'none',
      },
    },
  },
})

theme.breakpoints.values.sm = 414
theme.breakpoints.values.md = 768

const sm = theme.breakpoints.up('sm')
const md = theme.breakpoints.up('md')
const lg = theme.breakpoints.up('lg')
const xl = theme.breakpoints.up('xl')

/**
 * Provide default values for theme.overrides.MuiButton.
 * This avoids type errors.
 */
theme.overrides = theme.overrides || {}
theme.overrides.MuiButton = theme.overrides.MuiButton || {}

theme.overrides.MuiButton.contained = {
  padding: '0.4375rem 0.8125rem',
  borderRadius: '0.375rem',
  [md]: {
    padding: '0.625rem 1.3125rem',
  },
  [lg]: {
    padding: '0.8125rem 2rem',
    fontSize: '1.0625rem',
    borderRadius: '0.5625rem',
  },
}

theme.overrides.MuiButton.containedPrimary = {
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  },
}

const xsStyles = {
  paddingLeft: containerPaddingX.xs,
  paddingRight: containerPaddingX.xs,
}

const mdStyles = {
  paddingLeft: containerPaddingX.md,
  paddingRight: containerPaddingX.md,
  width: '81%',
  boxSizing: 'content-box',
  margin: '0 auto',
}

theme.overrides!.MuiContainer = {
  maxWidthLg: {
    ...xsStyles,
    [sm]: xsStyles,
    [md]: mdStyles,
    [lg]: {
      maxWidth: '65.375rem',
    },
    [xl]: {
      paddingLeft: containerPaddingX.xl,
      paddingRight: containerPaddingX.xl,
      maxWidth: '86.75rem',
    },
  },
}

theme.typography.body1 = {
  ...fontSizeVariants.body,
  [md]: {
    fontSize: '0.9375rem',
    lineHeight: '1.575rem',
  },
  [lg]: {
    fontSize: '1.1rem',
    lineHeight: '1.8rem',
  },
  [xl]: {
    fontSize: '1.25rem',
    lineHeight: '2.1rem',
  },
}

const getFontSrc = (name: string): string => {
  return `url("${process.env.GATSBY_FONTS_BUCKET_URL}/${name}.woff2") format("woff2"), url("${process.env.GATSBY_FONTS_BUCKET_URL}/${name}.woff") format("woff")`
}

theme.overrides.MuiCssBaseline = {
  '@global': {
    '@font-face': [
      {
        fontFamily: 'Gilroy',
        fontDisplay: 'swap',
        src: getFontSrc('Gilroy-Regular'),
      },
      {
        fontFamily: 'Gilroy',
        fontDisplay: 'swap',
        src: getFontSrc('Gilroy-Bold'),
        fontWeight: 'bold',
      },
    ],
    html: {
      '-webkit-text-size-adjust': '100%',
    },
  },
}

export const heading2: CSSProperties = {
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  fontSize: '1.3125rem',
  color: theme.palette.text.primary,
}

theme.typography.h1 = {
  fontSize: '3.75rem',
  lineHeight: '4.375rem',
}

theme.typography.h2 = {
  ...heading2,
  [md]: {
    fontSize: '2.1875rem',
    lineHeight: '2.5625rem',
  },
  [lg]: {
    fontSize: '2.5rem',
    lineHeight: '2.8rem',
  },
  [xl]: theme.typography.h1,
}

theme.typography.h3 = {
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}

theme.typography.subtitle1 = {
  color: theme.palette.text.secondary,
  fontSize: '0.8125rem',
  lineHeight: '1.125rem',
  textTransform: 'uppercase',
  fontWeight: 500,
  [md]: {
    fontSize: '0.9375rem',
    lineHeight: '1.3125rem',
  },
  [xl]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
}

export default theme
