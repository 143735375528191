import { CSSProperties } from '@material-ui/core/styles/withStyles'

type FontSizeVariants = {
  [key: string]: CSSProperties
}

/**
 * Ex: Body, sm, md, etc
 *
 * I created this object because the "typography" object in the theme uses
 * breakpoints. If a developer wanted to use a size variant without bringing in
 * the styles for the other breakpoints, there would be no good way to do that.
 * So, this object allows us to use size variants without accidentally bringing
 * in extra styles.
 */
export const fontSizeVariants: FontSizeVariants = {
  body: {
    fontSize: '0.9rem',
    lineHeight: '1.4rem',
  },
}
